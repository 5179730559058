/**
 * Edit user.
 */
import { apiGet, apiPut } from '@/util/api';
import { passwordIsValid } from '@/util/auth';

import PasswordValidFeedback from '@/components/PasswordValidFeedback.vue';

export default {
  name: 'UserEdit',
  components: {
    PasswordValidFeedback,
  },
  data() {
    return {
      loading: true,
      clients: [],
      roles: [
        { value: 'USER', text: this.$t('USER') },
        { value: 'CURATOR', text: this.$t('CURATOR') },
        { value: 'SUPERADMIN', text: this.$t('SUPERADMIN') },
      ],
      nameSaved: '',
      name: '',
      nameState: null,
      nameLoading: false,
      email: '',
      emailState: null,
      emailLoading: false,
      role: 'USER',
      roleState: true,
      isActive: true,
      isActiveState: true,
      isActiveLoading: false,
      client: null,
      clientState: true,
      clientLoading: false,
      password: '',
      passwordState: null,
      passwordLoading: false,
      passwordPolicy: null,
    };
  },
  async created() {
    // Prevent the user editing themselves
    if (this.$store.state.user.userId === this.$route.params.userId) {
      this.$router.push({ name: 'dashboard' });
    }

    // If the user was just created, indicate success.
    if (this.$route.params.created) {
      this.$bvToast.toast(this.$t('user_success_created'), {
        title: this.$t('common_success_title'),
        variant: 'success',
      });
    }

    // Get required data.
    await Promise.all([
      apiGet('/client'),
      apiGet('/user/password-policy'),
      apiGet(`/user/${this.$route.params.userId}`),
    ])
      .then(values => {
        let user, clients;
        [clients, this.passwordPolicy, user] = values;

        // Process clients data
        this.clients = clients.map(c => {
          return { value: c.id, text: c.name };
        });
        if (this.clients.length) {
          this.client = this.clients[0].value;
        }

        // Process user data
        this.name = user.name;
        this.nameSaved = user.name;
        this.isActive = user.isActive;
        this.role = user.role;
        this.client = user.client ? user.client.id : null;
      })
      .catch(() => {
        this.$bvToast.toast(this.$t('user_error_get_data'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      });

    this.loading = false;
  },
  computed: {
    /**
     * Determine whether the entered password is valid.
     */
    newPasswordIsValid() {
      return passwordIsValid(this.passwordPolicy, this.password);
    },
  },
  methods: {
    /**
     * Check the validity of the indicated input and set its state.
     * @param ref
     */
    checkInputValid(ref) {
      this[`${ref}State`] = this.$refs[ref].checkValidity();
    },

    /**
     * Set the password input valid state.
     */
    checkPasswordValid() {
      this.passwordState = !!(
        this.password &&
        this.newPasswordIsValid &&
        this.newPasswordIsValid.All
      );
    },

    /**
     * If the role is changed, ensure that the client field is valid.
     */
    onChangeRole() {
      if (['CURATOR', 'SUPERADMIN'].includes(this.role)) {
        this.client = null;
      } else {
        // USER role
        this.client = this.clients[0].value;
      }
    },

    /**
     * Update the specified property on the user.
     * @param property
     */
    async save(property) {
      if (!this[`${property}State`]) {
        return;
      }

      this[`${property}Loading`] = true;
      const data = {};
      data[property] = this[property];

      // Role / client are set differently because of restrictions and TypeORM reasons.
      if (property === 'client') {
        delete data.client;
        data.role = this.role;
        data.clientId = this.client;
      }

      let target = `/user/${this.$route.params.userId}`;
      if (['email', 'password'].includes(property)) {
        target = `/user/${property}/${this.$route.params.userId}`;
      }

      await apiPut(target, data)
        .then(() => {
          this.$bvToast.toast(this.$t('edit_user_success'), {
            title: this.$t('common_success_title'),
            variant: 'success',
          });

          // Update the display name if name was changed.
          if (property === 'name') {
            this.nameSaved = this.name;
          }
        })
        .catch(error => {
          let errorCode = '';
          try {
            errorCode = error.response.data.code;
          } catch {
            // Do nothing
          }
          if (errorCode && errorCode === 'AliasExistsException') {
            // The e-mail address is already in use by another user
            this.emailState = false;
            this.$bvToast.toast(
              this.$t('edit_user_error_email_already_in_user'),
              {
                title: this.$t('common_error_title'),
                variant: 'danger',
              },
            );
          } else {
            this.$bvToast.toast(this.$t('edit_user_error'), {
              title: this.$t('common_error_title'),
              variant: 'danger',
            });
          }
        })
        .finally(() => {
          this[`${property}Loading`] = false;
        });
    },
  },
};
